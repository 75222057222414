import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeProvider from "./Theme";

import { ContractProvider } from "./providers/ContractProvider";

createRoot(document.getElementById("root")!).render(
  <>
    <StrictMode>
      <CssBaseline />
      <ThemeProvider>
        <ContractProvider>
          <App />
        </ContractProvider>
      </ThemeProvider>
    </StrictMode>
  </>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//
